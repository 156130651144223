// Footer.js
import React from 'react';
import styled from 'styled-components';

// Footer Container Style
const FooterContainer = styled.div`
  width: 100%;
  background-color: #34495e;
  color: #ecf0f1;
  padding: 10px 20px;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>© 2024 BTR Software. All Rights Reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
