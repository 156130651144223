import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';
import axiosInstance from '../utils/axiosInstance'; // Use your custom axios instance

const RejectedRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const fetchRejectedRequests = () => {
      axiosInstance.get('/request/rejected') // Ensure this endpoint returns the right data
        .then(response => {
          const rejectedData = response.data.filter(request => request.status === 'rejected');
          setData(rejectedData);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    };

  useEffect(() => {
    fetchRejectedRequests();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      {/* fetchPendingRequests fonksiyonunu DataTable'a prop olarak geçiyoruz */}
      <DataTable title="İptal Edilen Talepler" data={data} isPendingPage={false} fetchData={fetchRejectedRequests} />
    </div>
  );
};

export default RejectedRequests;
