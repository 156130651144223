import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = () => {
  const token = localStorage.getItem('token');
  console.log('Token in Layout:', token); // Debugging token presence

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: '100%', margin: '0 auto' }}>
      <div style={{ display: 'flex', flexGrow: 1, maxWidth: '100%' }}>
        {token ? <Sidebar /> : <p>No Sidebar - User not authenticated</p>}
        <div style={{ flexGrow: 1, padding: '20px', maxWidth: '100%' }}>
          <Outlet />
        </div>
      </div>
      {token ? <Footer /> : <p>No Footer - User not authenticated</p>}
    </div>
  );
};

export default Layout;
