import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PendingRequests from './components/PendingRequests';
import CompletedRequests from './components/CompletedRequests';
import RejectedRequests from './components/RejectedRequests';
import Login from './components/Login';
import Layout from './components/Layout';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    const tokenChangeHandler = () => {
      const newToken = localStorage.getItem('token');
      console.log('Token changed in localStorage:', newToken);
      setToken(newToken);
    };

    window.addEventListener('storage', tokenChangeHandler);
    return () => window.removeEventListener('storage', tokenChangeHandler);
  }, []);

  useEffect(() => {
    console.log('Token state in App:', token);
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={token ? <Layout /> : <Navigate to="/login" replace />} >
          <Route path="pending-requests" element={<PendingRequests />} />
          <Route path="completed-requests" element={<CompletedRequests />} />
          <Route path="rejected-requests" element={<RejectedRequests />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
