import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';
import axiosInstance from '../utils/axiosInstance';

const POLLING_INTERVAL = 10000; // Poll every 10 seconds

const PendingRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect dışına taşıdık
  const fetchPendingRequests = () => {
    axiosInstance.get('/request')
      .then(response => {
        const pendingData = response.data.filter(request => request.status === "pending");
        setData(pendingData);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPendingRequests(); // İlk veri çekimi
    const interval = setInterval(fetchPendingRequests, POLLING_INTERVAL); // 10 saniyede bir veri çekme

    return () => clearInterval(interval); // Bileşen kaldırıldığında interval'i temizle
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      {/* fetchPendingRequests fonksiyonunu DataTable'a prop olarak geçiyoruz */}
      <DataTable title="Bekleyen Talepler" data={data} isPendingPage={true} fetchData={fetchPendingRequests} />
    </div>
  );
};

export default PendingRequests;
