import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import axiosInstance from '../utils/axiosInstance.js';

// Eklentiyi yükleyin
dayjs.extend(relativeTime);
dayjs.extend(duration);

const DataContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
`;

// Butonlar için ortak bir stil grubu oluşturduk
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
  background-color: #2c3e50;
  color: #ffffff;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #34495e;
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: center;
  font-size: 13px;
  color: #2d3436;
  border-bottom: 1px solid #e0e0e0;
`;

const StatusBadge = styled.span`
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: ${({ statusColor }) => statusColor};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const TableRow = styled.tr`
  transition: background-color 0.3s, transform 0.2s;
  
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #dff9fb;
    transform: scale(1.01);
  }
`;

const DeleteButton = styled.button`
  padding: 6px 12px;
  font-size: 12px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c0392b;
  }
`;

const RejectReasonBadge = styled.span`
  display: inline-block;
  padding: 3px 5px;
  color: black;
  background-color: #ecf0f1;
  border: 2px solid #e74c3c;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #c0392b;
    color: white;
  }
`;

const PaginationContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const PageButton = styled.button`
  background-color: ${props => (props.active ? '#34495e' : '#ecf0f1')};
  color: ${props => (props.active ? '#ffffff' : '#2c3e50')};
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #34495e;
    color: #ffffff;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

// Filter Card Styles
const FilterCard = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: calc(100% - 10px); /* Adjust width to match the table including container padding */
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: ${props => (props.isVisible ? '300px' : '0')};
  opacity: ${props => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
`;

// Filter Form Styles
const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const FilterInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  width: calc(100% - 30px); /* Adjust width to match table */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
    outline: none;
  }
`;

const FilterSelect = styled.select`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  width: calc(100% - 10px); /* Adjust width to match table */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Box-sizing'i ekle */
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
    outline: none;
  }
`;

// Filter Toggle Button
const FilterToggle = styled.button`
  background-color: #34495e;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  min-width: 150px;

  &:hover {
    background-color: #2c3e50;
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: 2px solid #3498db;
  }
`;

// Modern ve sade "Veriyi Güncelle" butonu
const UpdateButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`;

const WaitTime = styled.span`
  font-size: 12px; /* Daha küçük font boyutu */
  color: #2c3e50;
  border: 1px solid #bdc3c7; /* Gri bir border */
  border-radius: 4px;
  padding: 2px 6px; /* İçerik etrafında biraz boşluk */
  background-color: #ecf0f1; /* Hafif gri arka plan rengi */
  display: inline-block;
  margin-top: 5px;
`;

const DataTable = ({ title, data, isPendingPage, fetchData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCount, setFilteredCount] = useState(0);
  const [filters, setFilters] = useState({
    username: '',
    bonusType: '',
    createdAt: '',
    assignedTo: ''
  });
  const [showFilterCard, setShowFilterCard] = useState(false);
  const pageSize = 15;

  const bonusTypes = [
    { value: '', label: 'Bonus Seçiniz' },
    { value: 'freebet', label: '100 Deneme Freebet' },
    { value: 'freespin', label: '100 Deneme Freespin' },
    { value: '1005', label: '%150 Hoş Geldin - Casino' },
    { value: '1006', label: '%150 Hoş Geldin - Spor' },
    { value: '1003', label: '%50 Slot Bonusu' },
    { value: '1011', label: '%30 Freebet Bonusu' },
    { value: '1009', label: '%20 Slot Bonusu' },
    { value: '1001', label: '%15 Çevrimsiz Spor Bonusu' },
    { value: '1002', label: '%15 Canlı Casino Bonusu' },
    { value: '1100', label: '%30\'a Varan Kayıp Bonusu' },
    { value: '1110', label: '%10 Haftalık Spor Bonusu' },
    { value: '1111', label: '%10 Haftalık Casino Bonusu' },
    { value: '2001', label: '75 Ertesi Gün Freespin' },
    { value: '2002', label: '35 Hafta Sonu Freespin' },
    { value: '2003', label: '25 Happy Hours Freespin(2₺)' },
    { value: '2004', label: '50 Happy Hours Freespin(2₺)' },
    { value: '2005', label: '100 Happy Hours Freespin(2₺)' }
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const toggleFilterCard = () => {
    setShowFilterCard(prev => !prev);
  };

  // Apply filters and sort data by createdAt in descending order
  const filteredData = data
    .filter(item => {
      const matchesUsername = item.username.toLowerCase().includes(filters.username.toLowerCase());
      const matchesBonusType = item.bonusType.toLowerCase().includes(filters.bonusType.toLowerCase());
      const matchesAssignedTo = item.assignedTo.toLowerCase().includes(filters.assignedTo.toLowerCase());

      return matchesUsername && matchesBonusType && matchesAssignedTo;
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Filtrelenmiş verilerin sayısını hesapla
  useEffect(() => {
    setFilteredCount(filteredData.length);
  }, [filteredData]);

  const totalPages = Math.ceil(filteredData.length / pageSize);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const currentData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const deleteRequest = async (id) => {
    if (window.confirm('Bu talebi silmek istediğinizden emin misiniz?')) {
      try {
        const response = await axiosInstance.delete(`/request/delete?id=${id}`);
        if (response.data.message) {
          alert(response.data.message);
          fetchData(); // Silme işleminden sonra veriyi yeniden yükle
        }
      } catch (error) {
        console.error('Talep silinirken bir hata oluştu:', error);
        alert('Talep silinirken bir hata oluştu.');
      }
    }
  };

  const formatDuration = (startTime) => {
    const now = dayjs();
    const diff = now.diff(dayjs(startTime));
    const duration = dayjs.duration(diff);
  
    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())} saniye`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())} dakika`;
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())} saat ${Math.floor(duration.minutes())} dakika`;
    } else {
      return `${Math.floor(duration.asDays())} gün ${Math.floor(duration.hours() % 24)} saat`;
    }
  };
  
  return (
    
    <DataContainer>
      <h2 style={{ color: '#2c3e50', textAlign: 'center', marginBottom: '20px' }}>
        {title}({filteredCount})
      </h2>
      <p style={{ color: '#2c3e50', textAlign: 'center', marginBottom: '20px' }}>
        Bonuslar günlük olarak listelenmektedir. (00:00 - 23:59)
      </p>

      {/* Butonlar için grup */}
      <ButtonGroup>
        <FilterToggle onClick={toggleFilterCard}>
          {showFilterCard ? 'Filtre Gizle' : 'Filtre'}
        </FilterToggle>
        {!isPendingPage && (
                  <>
                    <UpdateButton onClick={fetchData}>Yenile</UpdateButton>
                  </>
                )}
        
      </ButtonGroup>

      <FilterCard isVisible={showFilterCard}>
  <FilterForm>
    {showFilterCard && (
      <>
        <FilterInput
          type="text"
          name="username"
          placeholder="Kullanıcı Adı"
          value={filters.username}
          onChange={handleFilterChange}
        />
        <FilterSelect
          name="bonusType"
          value={filters.bonusType}
          onChange={handleFilterChange}
        >
          {bonusTypes.map(bonus => (
            <option key={bonus.value} value={bonus.value}>
              {bonus.label}
            </option>
          ))}
        </FilterSelect>
        <FilterInput
          type="text"
          name="assignedTo"
          placeholder="Atandı"
          value={filters.assignedTo}
          onChange={handleFilterChange}
        />
      </>
    )}
  </FilterForm>
</FilterCard>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Kullanıcı Adı</TableHeader>
            <TableHeader>Bonus Türü</TableHeader>
            <TableHeader>Durum</TableHeader>
            <TableHeader>Red Nedeni</TableHeader>
            <TableHeader>Oluşturuldu</TableHeader>
            <TableHeader>Atandı</TableHeader>
            <TableHeader>İşlem</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {currentData.map(item => (
            <TableRow key={item._id}>
              <TableCell>{item.username}</TableCell>
              <TableCell>{getBonusName(item.bonusType)}</TableCell>
              <TableCell>
                <StatusBadge statusColor={getStatusColor(item.status)}>
                  {getStatusText(item.status)}
                </StatusBadge>
              </TableCell>
              <TableCell onClick={() => copyToClipboard(item.rejectReason || '—')}>
                <RejectReasonBadge>
                  {item.rejectReason || '—'}
                </RejectReasonBadge>
              </TableCell>
              <TableCell>
                {dayjs(item.createdAt).format('DD/MM/YY HH:mm:ss')}
                {isPendingPage && (
                  <>
                    <br />
                    <WaitTime>Bekleme Süresi: {formatDuration(item.createdAt)}</WaitTime>
                  </>
                )}
              </TableCell>
              <TableCell>{item.assignedTo}</TableCell>
              <TableCell>
              <DeleteButton onClick={() => deleteRequest(item._id)}>Sil</DeleteButton>
            </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <PaginationContainer>
        <PageButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &laquo;
        </PageButton>

        {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const pageNumber = startPage + index;
          return (
            <PageButton
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              active={currentPage === pageNumber}
            >
              {pageNumber}
            </PageButton>
          );
        })}

        <PageButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &raquo;
        </PageButton>
      </PaginationContainer>
    </DataContainer>
  );
};

// Bonus Name Retrieval Function
const getBonusName = (bonusId) => {
  const bonusNames = {
    freebet: '100 Deneme Freebet',
    freespin: '100 Deneme Freespin',
    1005: '%150 Hoş Geldin - Casino',
    1006: '%150 Hoş Geldin - Spor',
    1003: '%50 Slot Bonusu',
    1011: '%30 Freebet Bonusu',
    1009: '%20 Slot Bonusu',
    1001: '%15 Çevrimsiz Spor Bonusu',
    1002: '%15 Canlı Casino Bonusu',
    1100: '%30\'a Varan Kayıp Bonusu',
    1110: '%10 Haftalık Spor Bonusu',
    1111: '%10 Haftalık Casino Bonusu',
    2001: '75 Ertesi Gün Freespin',
    2002: '35 Hafta Sonu Freespin',
    2003: '25 Happy Hours Freespin(2₺)',
    2004: '50 Happy Hours Freespin(2₺)',
    2005: '100 Happy Hours Freespin(2₺)'
  };

  return bonusNames[bonusId] || 'Bilinmeyen Bonus Türü';
};

const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return '#3498db'; // Mavi
    case 'approved':
      return '#2ecc71'; // Yeşil
    case 'rejected':
      return '#e74c3c'; // Kırmızı
    default:
      return '#95a5a6'; // Gri (varsayılan durum)
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'pending':
      return 'Beklemede';
    case 'approved':
      return 'Onaylandı';
    case 'rejected':
      return 'Reddedildi';
    default:
      return 'Bilinmiyor'; // Varsayılan durum
  }
};

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    // Başarıyla kopyalandı
  });
};

export default DataTable;
