import React, { useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUser, FaLock } from 'react-icons/fa';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const InputGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  & svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #3498db;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;

  &:focus {
    border-color: #3498db;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:focus {
    outline: none;
  }
`;

const ErrorCard = styled.div`
  background-color: #e74c3c;
  color: white;
  padding: 12px;
  border-radius: 5px;
  width: 90%; /* Same width as button */
  max-width: 400px; /* Match the max-width of the form */
  margin-bottom: 15px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state for button
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    setError('');
    e.preventDefault();
    setLoading(true); // Start loading when button is clicked

    try {
      const response = await axiosInstance.post('/auth/login', { username, password });
      console.log('Login response:', response.data);
      const token = response.data.token;
      localStorage.setItem('token', token);
      console.log('Token stored in localStorage:', token);
      navigate('/pending-requests'); // Try navigating programmatically
      window.location.href = '/pending-requests'; // Fallback for immediate redirection
    } catch (err) {
      console.error('Login error:', err);
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Teknik bir hata oluştu. Lütfen tekrar deneyiniz.');
      }
    } finally {
      setLoading(false); // Stop loading after response is received
    }
  };

  return (
    <LoginContainer>
      <Form onSubmit={handleLogin}>
        <h2>Giriş</h2>
        {error && <ErrorCard>{error}</ErrorCard>}
        <InputGroup>
          <FaUser />
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Kullanıcı Adı"
            required
          />
        </InputGroup>
        <InputGroup>
          <FaLock />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Şifre"
            required
          />
        </InputGroup>
        <Button type="submit" disabled={loading}>Giriş Yap</Button> {/* Disable button if loading */}
      </Form>
    </LoginContainer>
  );
};

export default Login;
