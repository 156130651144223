import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaSignOutAlt } from 'react-icons/fa'; // Çıkış simgesi için

const Button = styled.button`
  width: 100%;
  padding: 15px 20px;
  margin-top: 10px;
  background-color: #e74c3c;
  color: white;
  border: none; /* Kenarlık kaldırıldı */
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  display: flex; /* Flex kullanarak simge ve metni yan yana getirme */
  align-items: center; /* Dikey ortalama */
  justify-content: center; /* Yatay ortalama */
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Geçiş efektleri eklendi */
  
  &:hover {
    background-color: #c0392b; /* Renk geçişi */
    transform: translateY(-2px); /* Hover etkisi */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Gölgeler eklendi */
  }

  &:active {
    transform: translateY(0); /* Buton basıldığında geri dönüş */
    box-shadow: none; /* Basıldığında gölge kaldırıldı */
  }
`;

const IconContainer = styled.span`
  margin-right: 10px; /* Simge ile metin arasındaki boşluk */
`;

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <Button onClick={handleLogout}>
      <IconContainer><FaSignOutAlt /></IconContainer> Çıkış Yap
    </Button>
  );
};

export default LogoutButton;
